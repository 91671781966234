import * as api from "../api";
import { StepButtonBar } from "../components/StepButtonBar";
import { TestHeader } from "../components/TestHeader";
import { GeneralTestResult, useTcuIdentity } from "../state";
import { ChargingParameters } from "../test-parameters";
import BatteryStdIcon from "@mui/icons-material/BatteryStd";
import BatteryUnknownIcon from "@mui/icons-material/BatteryUnknown";
import BoltIcon from "@mui/icons-material/Bolt";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import PowerIcon from "@mui/icons-material/Power";
import PowerOffIcon from "@mui/icons-material/PowerOff";
import SendIcon from "@mui/icons-material/Send";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Tooltip,
  Typography,
} from "@mui/material";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import React from "react";
import { useTranslation } from "react-i18next";

const TEST_DEV_MODE = process.env.REACT_APP_TEST_DEV_MODE === "true";

const SIGNAL_SOC = "percSOC";
const SIGNAL_CHARGE_PLUG = "KL15B";
const SIGNAL_AC_CURRENT = "iACMains";
const SIGNAL_AC_VOLTAGE = "uACMains";

interface SignalValues {
  fetchedAt: DateTime;
  soc: api.TcuSignal;
  plugged: api.TcuSignal;
  isPlugged: boolean | null;
  acCurrent: api.TcuSignal;
  acVoltage: api.TcuSignal;
  acPower: number | null;
}

function newSignalValues(): SignalValues {
  return {
    fetchedAt: DateTime.now(),
    soc: api.emptyTcuSignal(),
    plugged: api.emptyTcuSignal(),
    isPlugged: null,
    acCurrent: api.emptyTcuSignal(),
    acVoltage: api.emptyTcuSignal(),
    acPower: null,
  };
}

async function fetchSignalValues(
  abortSignal: AbortSignal | null,
  accessToken: string,
  boxId: string,
  initial: boolean,
): Promise<SignalValues> {
  if (!initial) {
    try {
      await api.resendStatusSignals(abortSignal, accessToken, boxId);
    } catch (err) {
      if (!api.errIsManualAbort(err)) {
        console.warn("failed to request resend status signals", err);
      }
    }
  }

  const signals = await api.getMultipleSignalValues(
    abortSignal,
    accessToken,
    boxId,
    [SIGNAL_SOC, SIGNAL_CHARGE_PLUG, SIGNAL_AC_CURRENT, SIGNAL_AC_VOLTAGE],
  );
  const {
    [SIGNAL_SOC]: soc,
    [SIGNAL_CHARGE_PLUG]: plugged,
    [SIGNAL_AC_CURRENT]: acCurrent,
    [SIGNAL_AC_VOLTAGE]: acVoltage,
  } = signals;

  const acPower =
    acCurrent.value !== null && acVoltage.value !== null
      ? acCurrent.value * acVoltage.value
      : null;
  const isPlugged = plugged.value === null ? null : plugged.value >= 0.5;

  return {
    fetchedAt: DateTime.now(),
    soc,
    plugged,
    isPlugged,
    acCurrent,
    acVoltage,
    acPower,
  };
}

function useSignalValues(): SignalValues {
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const { boxId } = useTcuIdentity();
  const accessToken = api.useAccessToken();
  const accessTokenRef = React.useRef(accessToken);
  accessTokenRef.current = accessToken;
  const [signalValues, setSignalValues] = React.useState(newSignalValues());
  React.useEffect(() => {
    const abortController = new AbortController();
    (async function once(initial: boolean) {
      try {
        const newSignalValues = await fetchSignalValues(
          abortController.signal,
          accessTokenRef.current,
          boxId,
          initial,
        );
        setSignalValues(newSignalValues);
      } catch (err) {
        if (api.errIsManualAbort(err)) return;

        api.errorNotifyUser(err, { snackbar, t, background: true });
      }

      setTimeout(once, ChargingParameters.signalUpdateIntervalMs, false);
    })(true);
    return () => abortController.abort();
  }, [boxId, setSignalValues, t, snackbar, accessTokenRef]);
  return signalValues;
}

function SignalValuesDisplay({
  plugged,
  soc,
  acCurrent,
  acVoltage,
  acPower,
}: {
  plugged: boolean | null;
  soc: number | null;
  acCurrent: number | null;
  acVoltage: number | null;
  acPower: number | null;
}) {
  const { t } = useTranslation();

  const acCurrentValueText =
    acCurrent?.toFixed(1) ?? t("charging.signal_state_unknown");
  const acVoltageValueText =
    acVoltage?.toFixed() ?? t("charging.signal_state_unknown");
  const powerTooltip = `${acCurrentValueText} A | ${acVoltageValueText} V`;

  return (
    <Stack
      alignItems="center"
      direction="row"
      spacing={2}
      sx={{ mt: 2, mb: 5 }}
    >
      <Chip
        color={plugged === null ? "secondary" : plugged ? "success" : "error"}
        icon={plugged === true ? <PowerIcon /> : <PowerOffIcon />}
        label={
          plugged === null
            ? t("charging.signal_state_unknown")
            : t(`charging.boolean_plugged_state.${plugged}`)
        }
      />
      <Chip
        color={
          soc === null
            ? "secondary"
            : soc <= ChargingParameters.socThreshold
              ? "success"
              : soc <= 98
                ? "warning"
                : "error"
        }
        icon={soc === null ? <BatteryUnknownIcon /> : <BatteryStdIcon />}
        label={
          soc === null
            ? t("charging.signal_state_unknown")
            : `${soc.toFixed()} %`
        }
      />
      <Tooltip title={powerTooltip}>
        <Chip
          color={acPower === null ? "secondary" : "info"}
          icon={<BoltIcon />}
          label={
            acPower === null
              ? t("charging.signal_state_unknown")
              : `${acPower.toFixed()} W`
          }
        />
      </Tooltip>
    </Stack>
  );
}

function SmartCheckboxIcon({
  checked,
  loading,
}: {
  checked: boolean | null;
  loading?: boolean;
}) {
  if (loading === true) return <CircularProgress size="1em" />;
  switch (checked) {
    case true:
      return <CheckBoxIcon color="success" />;
    case false:
      return <CheckBoxOutlineBlankIcon color="secondary" />;
    case null:
      return <IndeterminateCheckBoxIcon color="secondary" />;
  }
}

function PreparationSubstep({
  onNext,
  plugged,
  soc,
}: {
  onNext: () => void;
  plugged: boolean | null;
  soc: number | null;
}) {
  const { t } = useTranslation();

  const socOk = soc === null ? false : soc <= ChargingParameters.socThreshold;
  const canContinue = TEST_DEV_MODE || (plugged === false && socOk);

  return (
    <>
      <Typography gutterBottom variant="body1">
        {t("charging.preparation.instructions")}
      </Typography>
      <List>
        <ListItem>
          <ListItemIcon>
            <SmartCheckboxIcon checked={plugged === null ? null : !plugged} />
          </ListItemIcon>
          <ListItemText>
            {t("charging.preparation.vehicle_unplugged")}
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <SmartCheckboxIcon
              checked={
                soc === null ? null : soc <= ChargingParameters.socThreshold
              }
            />
          </ListItemIcon>
          <ListItemText>
            {t("charging.preparation.soc_below_threshold", {
              socThreshold: ChargingParameters.socThreshold,
            })}
          </ListItemText>
        </ListItem>
      </List>
      <Button disabled={!canContinue} onClick={onNext}>
        {t("next_step")}
      </Button>
    </>
  );
}

function PlugInSubstep({
  onNext,
  plugged,
}: {
  onNext: () => void;
  plugged: boolean | null;
}) {
  const { t } = useTranslation();

  const canContinue = TEST_DEV_MODE || plugged === true;

  return (
    <>
      <Typography gutterBottom variant="body1">
        {t("charging.plug_in.instructions")}
      </Typography>
      <List>
        <ListItem>
          <ListItemIcon>
            <SmartCheckboxIcon checked={plugged} />
          </ListItemIcon>
          <ListItemText>{t("charging.plug_in.vehicle_plugged")}</ListItemText>
        </ListItem>
      </List>
      <Button disabled={!canContinue} onClick={onNext}>
        {t("next_step")}
      </Button>
    </>
  );
}

interface ChargingSubstepResult {
  targetMaxAcPower: number;
  cmdLastSentAt: DateTime | null;
  targetReachedAt: DateTime | null;
  minPower: number | null;
  maxPower: number | null;
}

function ChargingSubstep({
  onNext,
  stepId,
  acPower,
  targetMaxAcPower,
}: {
  onNext: (result: ChargingSubstepResult) => void;
  stepId: string;
  acPower: number | null;
  targetMaxAcPower: number;
}) {
  const { t } = useTranslation();
  const [result] = React.useState((): ChargingSubstepResult => {
    return {
      targetMaxAcPower,
      cmdLastSentAt: null,
      targetReachedAt: null,
      minPower: null,
      maxPower: null,
    };
  });
  const onSendPowerCmdSuccess = React.useCallback(() => {
    result.cmdLastSentAt = DateTime.now();
  }, [result]);
  const { sendingPowerCmd, sendPowerCmdSuccess, sendPowerCmd } =
    useSendPowerCmd(targetMaxAcPower, ChargingParameters.powerCommandDuration, {
      onSendPowerCmdSuccess,
    });

  let powerReached = false;
  if (
    acPower !== null &&
    Math.abs(targetMaxAcPower - acPower) <= ChargingParameters.powerTolerance
  ) {
    powerReached = true;
    if (result.targetReachedAt === null)
      result.targetReachedAt = DateTime.now();
  }

  if (acPower !== null) {
    result.minPower =
      result.minPower === null ? acPower : Math.min(result.minPower, acPower);
    result.maxPower =
      result.maxPower === null ? acPower : Math.max(result.maxPower, acPower);
  }

  const canContinue =
    TEST_DEV_MODE ||
    (!sendingPowerCmd && sendPowerCmdSuccess === true && powerReached);

  return (
    <>
      <Typography gutterBottom variant="body1">
        {t(`charging.${stepId}.instructions` as unknown as any)}
      </Typography>
      <List>
        <ListItem>
          <ListItemIcon>
            <SmartCheckboxIcon
              checked={sendPowerCmdSuccess === true}
              loading={sendingPowerCmd}
            />
          </ListItemIcon>
          <ListItemText
            primary={t("charging.setting_max_ac_power", {
              maxAcPower: targetMaxAcPower,
            })}
            secondary={
              <Button
                color="secondary"
                disabled={sendingPowerCmd}
                endIcon={<SendIcon />}
                onClick={() => {
                  sendPowerCmd();
                }}
                size="small"
              >
                {t("charging.set_max_power_again")}
              </Button>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <SmartCheckboxIcon checked={powerReached} />
          </ListItemIcon>
          <ListItemText>{t("charging.waiting_for_power_target")}</ListItemText>
        </ListItem>
      </List>
      <Button disabled={!canContinue} onClick={() => onNext(result)}>
        {t("next_step")}
      </Button>
    </>
  );
}

function useSendPowerCmd(
  targetMaxAcPower: number,
  duration: number,
  options?: {
    onSendPowerCmdSuccess?: () => void;
    includeFallback?: boolean;
  },
): {
  sendingPowerCmd: boolean;
  sendPowerCmdSuccess: boolean | null;
  sendPowerCmd: (abortSignal?: AbortSignal) => Promise<void>;
} {
  const { onSendPowerCmdSuccess, includeFallback } = options ?? {};

  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const { boxId } = useTcuIdentity();
  const accessToken = api.useAccessToken();
  const [sendingPowerCmd, setSendingPowerCmd] = React.useState(false);
  const [sendPowerCmdSuccess, setSendPowerCmdSuccess] = React.useState<
    boolean | null
  >(null);
  const sendPowerCmd = React.useCallback(
    async (abortSignal?: AbortSignal) => {
      setSendingPowerCmd(true);
      try {
        await api.setMaxAcPower(
          abortSignal || null,
          accessToken,
          boxId,
          Math.round(targetMaxAcPower),
          duration,
          { includeFallback },
        );
        setSendPowerCmdSuccess(true);
        if (onSendPowerCmdSuccess !== undefined) onSendPowerCmdSuccess();
      } catch (err) {
        if (api.errIsManualAbort(err)) return;

        setSendPowerCmdSuccess(false);
        api.errorNotifyUser(err, { snackbar, t });
      }

      setSendingPowerCmd(false);
    },
    [
      boxId,
      duration,
      targetMaxAcPower,
      snackbar,
      t,
      accessToken,
      onSendPowerCmdSuccess,
      includeFallback,
    ],
  );
  React.useEffect(() => {
    if (sendPowerCmdSuccess !== null) return;

    const abortController = new AbortController();
    sendPowerCmd(abortController.signal);
    return () => abortController.abort();
  }, [sendPowerCmd, sendPowerCmdSuccess]);

  return { sendingPowerCmd, sendPowerCmdSuccess, sendPowerCmd };
}

function VerdictSubstep() {
  const { t } = useTranslation();
  const { sendingPowerCmd, sendPowerCmdSuccess, sendPowerCmd } =
    useSendPowerCmd(
      ChargingParameters.unlockChargingPower,
      ChargingParameters.unlockCommandDuration,
      {
        includeFallback: ChargingParameters.unlockIncludeFallback,
      },
    );
  return (
    <>
      <Typography gutterBottom sx={{ whiteSpace: "pre-wrap" }} variant="body1">
        {t(`charging.verdict.instructions`)}
      </Typography>
      <LoadingButton
        color={sendPowerCmdSuccess === false ? "error" : "secondary"}
        endIcon={<SendIcon />}
        loading={sendingPowerCmd}
        loadingPosition="end"
        onClick={() => sendPowerCmd()}
        variant="outlined"
      >
        {t("charging.verdict.resend")}
      </LoadingButton>
    </>
  );
}

interface BaseSubstepResult {
  snapshot: SignalValues;
  finishedAt: DateTime;
}

export interface ChargingResult extends GeneralTestResult {
  preparation: null | BaseSubstepResult;
  plugIn: null | BaseSubstepResult;
  startCharging: null | (BaseSubstepResult & ChargingSubstepResult);
  stopCharging: null | (BaseSubstepResult & ChargingSubstepResult);
}

interface State extends ChargingResult {
  step: number;
  canContinue: boolean;
}

function initState(): State {
  return {
    performedAt: null,
    success: false,
    step: 0,
    canContinue: false,
    preparation: null,
    plugIn: null,
    startCharging: null,
    stopCharging: null,
  };
}

type Action =
  | { type: "reset" }
  | { type: "finish-preparation"; snapshot: SignalValues }
  | { type: "finish-plugIn"; snapshot: SignalValues }
  | {
      type: "finish-startCharging";
      result: ChargingSubstepResult;
      snapshot: SignalValues;
    }
  | {
      type: "finish-stopCharging";
      result: ChargingSubstepResult;
      snapshot: SignalValues;
    };

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "reset":
      return initState();
    case "finish-preparation":
      return {
        ...state,
        step: state.step + 1,
        preparation: {
          snapshot: action.snapshot,
          finishedAt: DateTime.now(),
        },
      };
    case "finish-plugIn":
      return {
        ...state,
        step: state.step + 1,
        plugIn: {
          snapshot: action.snapshot,
          finishedAt: DateTime.now(),
        },
      };
    case "finish-startCharging":
      return {
        ...state,
        step: state.step + 1,
        startCharging: {
          ...action.result,
          snapshot: action.snapshot,
          finishedAt: DateTime.now(),
        },
      };
    case "finish-stopCharging":
      return {
        ...state,
        step: state.step + 1,
        canContinue: true,
        stopCharging: {
          ...action.result,
          snapshot: action.snapshot,
          finishedAt: DateTime.now(),
        },
      };
  }
}

export function ChargingStep({
  onResult,
}: {
  onResult: (result: ChargingResult) => void;
}) {
  const { t } = useTranslation();
  const signalValues = useSignalValues();
  const [state, dispatch] = React.useReducer(reducer, initState());

  let stepContent;
  switch (state.step) {
    case 0:
      stepContent = (
        <PreparationSubstep
          onNext={() =>
            dispatch({ type: "finish-preparation", snapshot: signalValues })
          }
          plugged={signalValues.isPlugged}
          soc={signalValues.soc.value}
        />
      );
      break;
    case 1:
      stepContent = (
        <PlugInSubstep
          onNext={() =>
            dispatch({ type: "finish-plugIn", snapshot: signalValues })
          }
          plugged={signalValues.isPlugged}
        />
      );
      break;
    case 2:
      stepContent = (
        <ChargingSubstep
          key="start_charging"
          onNext={(result) =>
            dispatch({
              type: "finish-startCharging",
              result,
              snapshot: signalValues,
            })
          }
          stepId="start_charging"
          acPower={signalValues.acPower}
          targetMaxAcPower={ChargingParameters.startChargingPower}
        />
      );
      break;
    case 3:
      stepContent = (
        <ChargingSubstep
          key="stop_charging"
          onNext={(result) =>
            dispatch({
              type: "finish-stopCharging",
              result,
              snapshot: signalValues,
            })
          }
          stepId="stop_charging"
          acPower={signalValues.acPower}
          targetMaxAcPower={ChargingParameters.stopChargingPower}
        />
      );
      break;
    case 4:
      stepContent = <VerdictSubstep />;
      break;
    default:
      throw new Error("unreachable step");
  }

  return (
    <Box>
      <TestHeader testKey="charging" />
      <SignalValuesDisplay
        plugged={signalValues.isPlugged}
        soc={signalValues.soc.value}
        acCurrent={signalValues.acCurrent.value}
        acVoltage={signalValues.acVoltage.value}
        acPower={signalValues.acPower}
      />
      <Stepper activeStep={state.step}>
        <Step>
          <StepLabel>{t("charging.preparation.step_name")}</StepLabel>
        </Step>
        <Step>
          <StepLabel>{t("charging.plug_in.step_name")}</StepLabel>
        </Step>
        <Step>
          <StepLabel>{t("charging.start_charging.step_name")}</StepLabel>
        </Step>
        <Step>
          <StepLabel>{t("charging.stop_charging.step_name")}</StepLabel>
        </Step>
        <Step>
          <StepLabel>{t("charging.verdict.step_name")}</StepLabel>
        </Step>
      </Stepper>
      <Paper elevation={1} sx={{ my: 2, p: 3 }}>
        {stepContent}
      </Paper>
      <StepButtonBar
        onContinue={() => {
          onResult({
            performedAt: DateTime.now(),
            success: state.canContinue,
            preparation: state.preparation,
            plugIn: state.plugIn,
            startCharging: state.startCharging,
            stopCharging: state.stopCharging,
          });
        }}
        onRetry={() => {
          dispatch({ type: "reset" });
        }}
        onSkip={() => {
          onResult({
            performedAt: DateTime.now(),
            success: null,
            preparation: state.preparation,
            plugIn: state.plugIn,
            startCharging: state.startCharging,
            stopCharging: state.stopCharging,
          });
        }}
        success={state.canContinue}
        disabled={false}
      />
    </Box>
  );
}
